.App {
    /* max-width: 1200px; */
    justify-content: center;
    width: 100%;
    margin: auto;
    height: auto;
    min-height: 100vh;

    /* box-shadow: 0 0 5px #ccc; */
  }
  
div.main {
  /* justify-content: center;
  display: flex; */
  max-width:1200px; 
  margin: auto;
  font-family: 'Noto Sans KR', sans-serif !important;
  font-weight: 400;
}

.navbar {
  background-color:rgba(255, 38, 38, 0.89);
  font-weight: 500;
  /* color: white !important; */
  font-family: 'Noto Sans KR', sans-serif !important;
  /* flex: 1;
  display: flex;
  justify-content: space-between; */
}

.main-navbar-navlink{
  color: white;
  margin-right: 10px;
}

a {
  text-decoration: none;
}

.page-footer {
  /* display: flex; */
  margin-top: 150px;
  border-top: 1px solid rgb(240, 235, 227);
}

div.card{
  border: 0px;
  border-radius: 0%;
  background-color: transparent;
}

span.separator{
  font-size:11px;
  margin-left: 10px;
  margin-right: 10px;
  height: 100px;
  border-left: solid 0.1px rgba(0, 0, 0, 0.3);
}


.subnav-container{
  margin-top: 30px !important;
  margin-bottom:40px !important;
  justify-content:center !important;
  padding-bottom: 10px !important;
  border-bottom: red 1px inset;
}


.subnav-selected{
  color: rgba(255, 38, 38, 0.89);
  font-size: 20px;
  padding-bottom:10px;
  padding-left:30px;
  padding-right:30px;
  padding-top:10px;

  border-bottom: white 2px solid;

  border-top: rgba(255, 38, 38, 0.89) 1px solid;
  border-left: rgba(255, 38, 38, 0.89)1px solid;
  border-right: rgba(255, 38, 38, 0.89) 1px solid;
}

.subnav-notselected{
  color: rgba(0,0,0, 0.7);
  font-size: 20px;
}

.subnav-selected:hover{
  color: black;
  
}
.subnav-notselected:hover{
  color: black;
  text-shadow: 15px;
}

.update-time {
  margin-top: 15px !important;
  margin-left: auto !important;
  margin-right: 25px !important;
  font-size: 13px !important;
}

/* .genre-subnav-container {
  display: flex;
  justify-content: left;
  padding-left: 0px;
  margin-left: 0px;
  border-bottom: rgba(255, 23, 0, 0.5) 1px solid;
} */

/* .genre-subnav-selected{
  width: 80px;
  color: red;
  display: flex;
  align-content: center;
  justify-content: center;

  border-bottom: white 1px solid;
}


.genre-subnav-unselected{
  font-size: 14px;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 80px;
} */

/* --------------------------
        home page  
-------------------------- */

.home-logo{
  width: 50px;
}

.home-card{
  width:80px;
  height:80px;
}

.home-card-img{
  width:100%;
  height: 80px;
  object-fit: cover; 
  border: solid  rgba(0, 0, 0, 0.15) 0.1px;
  box-shadow: 10px;
  border-radius: 0px;
}

.home-card-title{
  width: 80px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  position: absolute;
  top: 50px;
  left: 0px;
  font-size: 11px;
  text-decoration: none;
  color:white;
  
  /* justify-content: center; */
  padding: 1px;
}

.home-card-info{
  width: 80px;
  height: 15px;
  padding: 1px;
  background-color: rgba(0, 0, 0, 0.6);
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  position: absolute;
  top: 65px;
  left: 0px;
  font-size: 11px;
  color:wheat;
}

.home-ico-adult{
  width: 15px;
  height: 15px;
  margin: -30px 0px 0px 46px;
}

/* style={{width:'100px', height:'100px'}} */



/* --------------------------
        genre list page  
-------------------------- */
.genre-card-title{
  width: 80px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  position: absolute;
  top: 65px;
  left: 0px;
  font-size: 11px;
  text-decoration: none;
  color:white;
  
  /* justify-content: center; */
  padding: 1px;
}

.weekday-card-title{
  width: 80px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  position: absolute;
  top: 65px;
  left: 0px;
  font-size: 11px;
  text-decoration: none;
  color:white;
  
  /* justify-content: center; */
  padding: 1px;
}



/* --------------------------
        artist detail page  
-------------------------- */
.artist-card-img{
  display: block;
  box-shadow: 0 0 5px gray;
  width: 300px; 
  height: 200px;
  object-fit: cover; 
  object-position: 50% 50%;
  border-radius: 0px;
}

.artist-ico-adult{
  position: absolute;
  top: 24px;
  left: 260px;
  width: 33px;
  /* height: 50px; */
  font-size: 11px;
  text-decoration: none;
  color:white;
}


.artist-detail-name{
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: 500;
}

.artist-detail-count{
  font-size: 20px;
  margin-bottom: 20px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
}

.artist-card-title{
  font-size: 17px;
  color: black;
}



/* --------------------------
        list page  
-------------------------- */
/* .css-1ex1afd-MuiTableCell-root{
  border-bottom: 0px !important;
  padding: 10px !important;
  align-content: center;
  justify-content: center;
} */


.search-box{
  margin-left: 0px !important;
  width: 1170px !important;
  border: solid rgba(0, 0, 0, 0.35) 0.1px;
  /* box-shadow: 0px !important; */
  border-radius: 0px !important;
}

.genreToggle {
  /* font-family: 'Nanum Gothic', sans-serif !important; */
  /* font-family: 'Noto Sans KR', sans-serif !important; */
  font-weight: 500 !important;
  font-size: 10.5px !important;
  height: 30px !important;
}

.list-table-cell{
  padding: 10px !important;
  border-bottom: 0px !important;
}

.list-card-img{
  /* width:100%; */
  width: 165px;
  height: 100px;
  /* background-position: center center;
  background-repeat: no-repeat; */
  /* box-shadow: 0 0 10px #ccc; */
  object-fit: cover; 
  /* object-position: 50% 50%; */
  border: solid  rgba(0, 0, 0, 0.15) 0.1px;
  box-shadow: 10px;
  border-radius: 0px;
}

.list-ico-adult{
  width: 20px;
  margin: 1px 0px 0px 140px;
}

.list-card-title-body {
  /* background-color: white; */
  height: 25px;
  margin-top: 3px;
  
  align-content: flex-end;
  padding: 0px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.list-card-title {
  /* background-color: white; */
  /* height: 25px; */
  /* width: 50px; */
  /* display: inline-flex; */
  font-size: 15px;
  color: black;
  text-decoration: None;
  /* align-content: center; */
  /* margin-top: 3px; */
}


.list-card-artist-body{
  /* background-color: white; */
  
  height: 20px;
  margin-top: -2px;
  padding: 0px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.list-card-artist{
  /* background-color: white; */
  height: 20px;
  font-size: 13px;
  color: rgb(56, 56, 56);
  text-decoration: None;
  margin-top: 0px;
  padding: 0px;
}

.list-pagination-stack{
  width: 100%;
  margin-top: 100px;
  padding-right: 20px;
}


/* --------------------------
        detail page  
-------------------------- */
.detail-img{
  /* width: 500;
  height: 300px; */
  /* background-position: center center;
  background-repeat: no-repeat; */
  box-shadow: 0 0 5px gray;
  width: 500px; 
  height: 300px;
  /* object-fit: cover;  */
  /* object-position: 50% 50%; */
  line-height: 150px;
  /* justify-content: center; */
  text-align: center;
  /* margin-right: 10px; */
}

.genre-link{
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
}

.date-link{
  text-decoration: none;
  color: rgba(0, 0, 0, 0.784);
}

.artist-link{
  text-decoration: none;
  color: rgba(0, 0, 0, 0.784);
}

.publisher-logo{
  width: 30px;
  height: 30px;
  border-radius: 20px;
}

.detail-other-box{
  width: 500px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1) ;

}

.detail-other-worklist {
  width: 500px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  height: 140px;
  background-color: transparent;
}

.detail-card-artist{
  color:rgb(0, 0, 0);
  width: 50%;
  margin-bottom: 5px;
}

.detail-card{
  width: 150px;
  margin-right:30px;
  flex-shrink: 0;
}

.detail-card-img{
  width: 150px;
  height: 100px;
  object-fit: cover; 
  object-position: 50% 50%;
  border: solid  rgba(0, 0, 0, 0.15) 0.1px;
  box-shadow: 10px;
  border-radius: 0px;
}

.detail-card-link{
  font-size: 14px;
  color: black;
  text-decoration: none;
}

.detail-card-title-body {
  width: 130px;
  height: 30px;
  padding: 4px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}


/* --------------------------
        nav search
-------------------------- */
/* .css-1ex1afd-MuiTableCell-root{
  border-bottom: 0px !important;
  padding: 10px !important;
  align-content: center;
  justify-content: center;
} */

.search-card-img{
  /* width:100%; */
  width: 165px;
  height: 100px;
  /* background-position: center center;
  background-repeat: no-repeat; */
  /* box-shadow: 0 0 10px #ccc; */
  object-fit: cover; 
  /* object-position: 50% 50%; */
  border: solid  rgba(0, 0, 0, 0.15) 0.1px;
  box-shadow: 10px;
  border-radius: 0px;
}

.search-ico-adult{
  width: 20px;
  margin: 1px 0px 0px 140px;
}

.search-card-title-body {
  /* background-color: white; */
  height: 25px;
  margin-top: 3px;
  
  align-content: flex-end;
  padding: 0px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.search-card-title {
  /* background-color: white; */
  /* height: 25px; */
  /* width: 50px; */
  /* display: inline-flex; */
  font-size: 15px;
  color: black;
  text-decoration: None;
  /* align-content: center; */
  /* margin-top: 3px; */
}


.search-card-artist-body{
  /* background-color: white; */
  
  height: 20px;
  margin-top: -2px;
  padding: 0px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.search-card-artist{
  /* background-color: white; */
  height: 20px;
  font-size: 13px;
  color: rgb(56, 56, 56);
  text-decoration: None;
  margin-top: 0px;
  padding: 0px;
}
